<template>
    <div id="clinic-dashboard-home">
        <vs-row vs-type="grid" class="top-grids current-number-grids">
            <InjectableAppointmentsWidget :totalAppointments="formatCurrency(currentAppointments.total_appointments, 0)"
                :loadingChart="loadingCharts.totalAppointments" :isFetchingMainFilters="isFetchingMainFilters" />
            <PatientMixWidget
                :returningPatientsPercentage="customerMix.returning_patients_percentage ? customerMix.returning_patients_percentage : 0"
                :returningPatients="customerMix.returning_patients ? customerMix.returning_patients : 0"
                :firstTimePatientsPercentage="customerMix.first_time_patients_percentage ? customerMix.first_time_patients_percentage : 0"
                :firstTimePatients="customerMix.first_time_patients ? customerMix.first_time_patients : 0"
                :loadingChart="loadingCharts.customerMix" :isFetchingMainFilters="isFetchingMainFilters" />
            <HaventBookedPatientsWidget
                :numberOfClients="(currentNumberOfClientsNotBooked.number_of_clients) ? currentNumberOfClientsNotBooked.number_of_clients : 0"
                :loadingChart="loadingCharts.totalAppointments" :isFetchingMainFilters="isFetchingMainFilters" />
            <div class="widget-container chart-widget">
                <div class="widget-title text-center">Total Spend
                    <QuestionTooltip>
                        <template slot="header">Total Spend</template>
                        <template slot="body">Total amount of product spend, including S4 medication, skin care,
                            consumables and equipment. Does not include scripting fees</template>
                    </QuestionTooltip>
                </div>
                <div class="widget-count text-center">
                    {{ (!loadingCharts.injectablesSpend && !isFetchingMainFilters) ?
                        '$' + formatCurrency(currentInjectablesSpend.amount,
                            2) : '' }}
                    <div class="skeleton skeleton-line" v-if="loadingCharts.injectablesSpend || isFetchingMainFilters"
                        style="--c-w: 60px;"></div>
                </div>
            </div>
        </vs-row>

        <vs-row vs-type="flex">
            <div class="widget-container chart-widget"
                :style="{ paddingBottom: '15px', width: '100%', minHeight: '280px', justifyContent: 'flex-start' }">
                <!-- <div class="lds-dual-ring" ></div> -->
                <vs-row vs-justify="space-between">
                    <div class="widget-title"
                        :style="{ display: 'flex', width: '100%', justifyContent: 'space-between' }">
                        Appointments per month ({{ selectedNurseName ? selectedNurseName : "Your Clinic" }} vs.
                        {{ selectedNurseName ? "Benchmark Nurses" : "Benchmark Clinics" }})

                        <QuestionTooltip>
                            <template slot="header">Appointments per month ({{ selectedNurseName ? selectedNurseName : "Your Clinic" }} vs.
                                {{ selectedNurseName ? "Benchmark Nurses" : "Benchmark Clinics"}})</template>
                            <template slot="body">Number of complete injectables appointments </template>
                        </QuestionTooltip>
                    </div>
                </vs-row>
                <div style="background: #F2FAF8; border: 0.75px solid #0E4333; border-radius: 6px; padding: 8px; margin: 10px 0; font-size: 12px; line-height: 24px; font-weight: 500; width: 100%">
                    This benchmark is based off the average monthly appointments from a large sample of clinics, with adjustments for clinics that are estimated to be part-time. As a result, the benchmark will be higher than the true average for the network.
                </div>
                <div style="position: relative;  width: 100%; height: 100%;">
                    <div class="skeleton skeleton-chart-columns" style="
                            --cols: 10;
                            --col-w: 30px;
                            --col-gap: 20px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        " v-if="loadingCharts.appointmentPerformance || isFetchingMainFilters"></div>
                    <template v-else>
                        <BarChart v-if="appointmentPerformanceData.datasets.length"
                            :chartData="appointmentPerformanceData" :chartOptions="appointmentPerformanceOptions"
                            :height="230" :width="250" />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
        </vs-row>

        <vs-row vs-type="flex">
            <div :style="{ width: '100%' }">
                <FeedbackWidget slug="insights-clinic-dashboard-feedback" :clinicId="selectedClinic" />
            </div>
        </vs-row>

        <vs-row vs-type="grid" class="mix-grids">
            <div class="widget-container chart-widget" :style="{ width: '100%', minHeight: '280px' }">
                <vs-row class="mb-2" vs-justify="space-between">
                    <div class="widget-title"
                        :style="{ display: 'flex', width: '100%', justifyContent: 'space-between' }">
                        Product
                        Mix (Based on Spend)
                        <QuestionTooltip>
                            <template slot="header">Product Mix (Based on Spend)</template>
                            <template slot="body">Breakdown of product spend by product category. Includes S4 products,
                                skin care and consumables</template>
                        </QuestionTooltip>
                    </div>
                    <div class="widget-options flex" style="justify-content: flex-end;">
                    </div>
                </vs-row>
                <div :style="{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }">
                    <div v-if="loadingCharts.productMix || isFetchingMainFilters" class="skeleton skeleton-circle"
                        style="--c-s: 150px;
                        margin: 0 auto;"></div>
                    <template v-else>
                        <template v-if="productMixData.datasets.length">
                            <DoughnutChart :chartData="productMixData" :chartOptions="productMixOptions" :height="250"
                                :width="270" />
                        </template>
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>

                </div>
            </div>

            <TreatmentMixWidget :selectedNurseName="selectedNurseName"
                :treatmentMixYourClinicData="treatmentMixYourClinicData" :loadingChart="loadingCharts.treatmentMix"
                :isFetchingMainFilters="isFetchingMainFilters" />
        </vs-row>

        <vs-row vs-type="flex">
            <div class="overall-market">Overall Market</div>
        </vs-row>

        <vs-row vs-type="grid" class="overall-market-grids">
            <div class="widget-container chart-widget" :style="{ paddingBottom: '5px', minHeight: '280px' }">
                <vs-row vs-justify="space-between">
                    <div class="widget-title"
                        :style="{ display: 'flex', width: '100%', justifyContent: 'space-between' }">
                        Appointment Volumes (Aus Wide)
                        <QuestionTooltip>
                            <template slot="header">Appointment Volumes (Aus Wide)</template>
                            <template slot="body">Number of appointments completed Australia wide across the Fresh
                                Clinics network. </template>
                        </QuestionTooltip>
                    </div>
                    <div class="widget-options flex" style="justify-content: flex-end;">
                    </div>
                </vs-row>

                <div style="position: relative;  width: 100%; height: 100%; padding-top: 15px;">
                    <div class="skeleton skeleton-chart-columns" style="
                            --cols: 10;
                            --col-w: 30px;
                            --col-gap: 20px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        " v-if="loadingCharts.appointmentVolumes || isFetchingMainFilters"></div>
                    <template v-else>
                        <BarChart v-if="appointmentVolumesData.datasets.length" :chartData="appointmentVolumesData"
                            :chartOptions="overallMarketChartOptions" :height="230" :width="250" />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>

            <div class="widget-container chart-widget" :style="{ paddingBottom: '5px', minHeight: '280px' }">
                <vs-row vs-justify="space-between">
                    <div class="widget-title"
                        :style="{ display: 'flex', width: '100%', justifyContent: 'space-between' }">
                        Product
                        Volumes (Aus Wide)

                        <QuestionTooltip>
                            <template slot="header">Product Volumes (Aus Wide)</template>
                            <template slot="body">Value of products purchased. Does not include scripting
                                fees</template>
                        </QuestionTooltip>
                    </div>
                    <div class="widget-options flex" style="justify-content: flex-end;">
                    </div>
                </vs-row>


                <div style="position: relative;  width: 100%; height: 100%; padding-top: 15px;">
                    <div class="skeleton skeleton-chart-columns" style="
                            --cols: 10;
                            --col-w: 30px;
                            --col-gap: 20px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        " v-if="loadingCharts.productVolumes || isFetchingMainFilters"></div>
                    <template v-else>
                        <BarChart v-if="productVolumesData.datasets.length" :chartData="productVolumesData"
                            :chartOptions="overallMarketChartOptions" :height="230" :width="250" />
                        <div v-else class="no-data-available">No Data Available</div>
                    </template>
                </div>
            </div>
        </vs-row>
    </div>
</template>
<script>
import utils from '@/assets/utils'
import BarChart from '../../components/insights/charts/BarChart.vue'
// import DonutChart from '../../components/insights/charts/DonutChart.vue'
import DoughnutChart from '../../components/insights/charts/DoughnutChart.vue'
import ChartLegend from '../../components/insights/charts/ChartLegend'
import LineChart from '../../components/insights/charts/LineChart.vue'
import SearchAI from '../../components/insights/common/SearchAI'
import QuestionTooltip from '../../components/insights/common/QuestionTooltip'
import { mapActions } from "vuex";
import moment from "moment";
import _, { filter, groupBy, sumBy, slice, find, omitBy } from "lodash";
import InjectableAppointmentsWidget from "../../components/insights/widgets/InjectableAppointmentsWidget.vue";
import PatientMixWidget from "../../components/insights/widgets/PatientMixWidget.vue";
import HaventBookedPatientsWidget from "../../components/insights/widgets/HaventBookedPatientsWidget.vue";
import TreatmentMixWidget from "../../components/insights/widgets/TreatmentMixWidget.vue";
import FeedbackWidget from "../../components/insights/widgets/FeedbackWidget.vue";

export default {
    props: {
        selectedNurse: {
            type: String,
            default: ''
        },
        selectedNurseName: {
            type: String,
            default: ''
        },
        selectedClinic: {
            type: Array,
            default: () => []
        },
        isFetchingMainFilters: {
            type: Boolean,
            default: false
        },
        selectedDate: {
            type: Array,
            default: () => []
        },
        loadingDropdownFilter: {
            type: Boolean,
            default: false
        },
        forceFetch: {
            type: Boolean,
            default: false
        },
        selectedConfiguration: {
            type: Object,
            default: () => { }
        }
    },
    components: {
        BarChart,
        DoughnutChart,
        ChartLegend,
        LineChart,
        SearchAI,
        QuestionTooltip,
        InjectableAppointmentsWidget,
        PatientMixWidget,
        HaventBookedPatientsWidget,
        TreatmentMixWidget,
        FeedbackWidget
    },
    data() {
        return {
            loadingCharts: {
                totalAppointments: false,
                customerMix: false,
                totalPurchaseAmount: false,
                appointmentPerformance: false,
                lineChart: false,
                injectablesSpend: false,
                clientsNotBooked: false,
                treatmentMix: false,
                appointmentVolumes: false,
                productVolumes: false,
                productMix: false
            },
            currentPatients: {
                total_appointments: 0,
                growth_percentage: 0,
                prev_month_total_patients: 0,
                total_patients: 0
            },
            currentAppointments: {
                total_appointments: 0
            },
            customerMix: {
                first_time_patients: 0,
                returning_patients: 0,
                first_time_patients_percentage: 0,
                returning_patients_percentage: 0
            },
            currentInjectablesSpend: {
                amount: 0
            },
            currentProductPurchases: {
                growth_percentage: 0,
                prev_month_total_purchase_amount: 0,
                total_purchase_amount: 0
            },
            currentNumberOfClientsNotBooked: {
                number_of_clients: 0
            },
            appointmentPerformanceData: {
                "labels": [],
                "datasets": []
            },
            appointmentPerformanceOptions: {
                interaction: {
                    intersect: false,
                    axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        pointStyleWidth: 14,
                        boxHeight: 10,
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function (context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function (context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                            text: ''
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            tickColor: '#EFF1F4'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid: {
                            display: true,
                            color: '#EFF1F4'
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }
            },
            productMixData: {
                labels: [],
                datasets: []
            },
            productMixOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'left',
                        align: 'center',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12
                            },
                        },
                        pointStyleWidth: 14,
                        boxHeight: 10,
                        onClick: function (event, legendItem, legend) {
                            return;
                        }
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function (context) {
                                return {
                                    borderColor: context.dataset.backgroundColor[context.dataIndex],
                                    backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                                };
                            },
                            label: function (context) {
                                // let label = context.label || '';

                                let hoverLabel = context.dataset.hoverLabel[context.dataIndex] || '';
                                return hoverLabel;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    }
                },
            },
            treatmentMixYourClinicData: {
                labels: [],
                datasets: []
            },
            treatmentMixTopData: [
                { sets: ['Filler Only'], size: 0 },
                { sets: ['Anti-wrinkle'], size: 0 },
                { sets: ['Filler Only', 'Anti-wrinkle'], size: 0 },
            ],
            appointmentVolumesData: {
                labels: [],
                datasets: []
            },
            productVolumesData: {
                "labels": [],
                "datasets": []
            },
            overallMarketChartOptions: {
                interaction: {
                    intersect: false,
                    axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        pointStyleWidth: 14,
                        boxHeight: 10,
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            color: "#0E4333",
                            font: {
                                family: "InterMedium",
                                size: 12,
                            }
                        }
                    },
                    title: {
                        display: false,
                        text: ''
                    },
                    tooltip: {
                        mode: 'index',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function (context) {
                                return {
                                    borderColor: context.dataset.backgroundColor,
                                    backgroundColor: context.dataset.backgroundColor,
                                };
                            },
                            labelPointStyle: function (context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            },
                            label: function (context) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }

                                let changedValue = 0
                                const value = parseInt(context.formattedValue)
                                if (context.dataIndex) {
                                    // check if context data is an object
                                    if (typeof context.dataset.data[context.dataIndex - 1] === 'object') {
                                        // context data is an array of objects
                                        changedValue = value - context.dataset.data[context.dataIndex - 1].y
                                    } else {
                                        // context data is an array of numbers
                                        changedValue = value - context.dataset.data[context.dataIndex - 1]
                                    }

                                    label += (changedValue > 0) ? '+' + changedValue + '%' : changedValue + '%'
                                } else if (context.dataIndex === 0 && context.dataset.originalData && context.dataset.originalData.length > context.dataset.data.length) {
                                    // check from originalData to get the change value for the first column bar
                                    const prevIndex = context.dataset.originalData.length - context.dataset.data.length
                                    // check if context data is an object
                                    if (typeof context.dataset.data[prevIndex - 1] === 'object') {
                                        // context data is an array of objects
                                        changedValue = value - context.dataset.originalData[prevIndex - 1].y
                                    } else {
                                        // context data is an array of numbers
                                        changedValue = value - context.dataset.originalData[prevIndex - 1]
                                    }

                                    label += (changedValue > 0) ? '+' + changedValue + '%' : changedValue + '%'
                                } else {
                                    // display empty tooltip if there's no previous month data
                                    label = ''
                                }

                                return label;
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                },
                scales: {
                    y: {
                        title: {
                            display: false,
                            text: ''
                        },
                        ticks: {
                            display: false
                        },
                        grid: {
                            color: '#EFF1F4',
                            borderColor: '#EFF1F4',
                            borderWidth: 0,
                            tickWidth: 0,
                            drawTicks: false,
                        }
                    },
                    x: {
                        title: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 12,
                            },
                        }
                    }
                }
            },
            lineChartOptions: {
                interaction: {
                    intersect: false,
                    axis: 'x',
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        enabled: false,
                        // position: 'nearest',
                        // mode: 'x',
                        caretSize: 0,
                        caretPadding: 15,
                        backgroundColor: 'rgba(0,0,0, 0.7)',
                        titleFont: {
                            family: 'InterSemiBold',
                            size: 16
                        },
                        bodySpacing: 3,
                        bodyFont: {
                            family: 'Inter',
                            size: 14
                        },
                        // padding: 10,
                        // caretPadding: 15,
                        // caretSize: 0,
                        // external: externalTooltipHandler
                        usePointStyle: true,
                        callbacks: {
                            labelColor: function (context) {
                                return {
                                    borderColor: context.dataset.borderColor,
                                    backgroundColor: context.dataset.borderColor,
                                };
                            },
                            labelPointStyle: function (context) {
                                return {
                                    pointStyle: 'circle',
                                };
                            }
                        },
                        boxWidth: 10,
                        boxHeight: 10,
                        boxPadding: 8,
                        borderWidth: 0,
                        padding: 15
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            pointStyle: "circle",
                            usePointStyle: true,
                            padding: 20,
                            font: {
                                family: "InterMedium",
                                size: 12
                            },
                            boxWidth: 10,
                            boxHeight: 10,
                            color: '#0E4333'
                        }
                    },
                    title: {
                        display: true,
                        text: '',
                    },
                },
                layout: {
                },
                scales: {
                    y: {
                        title: {
                            display: false
                        },
                        min: 0,
                        max: 100, // null
                        grid: {
                            color: '#E4E4E4',
                            // borderColor: 'blue', // remove
                            // tickColor: 'yellow', // none
                            borderWidth: 0,
                            borderDash: [10, 5],
                            tickWidth: 0,
                            drawTicks: false,
                            lineWidth: 2
                        },
                        ticks: {
                            display: false
                        }
                    },
                    x: {
                        title: {
                            display: false
                        },
                        grid: {
                            display: false
                        },
                        ticks: {
                            color: "#0E4333",
                            font: {
                                family: "Inter",
                                size: 15,
                            },
                        }
                    }
                }
            },
        }
    },
    methods: {
        moment(date) {
            return moment(date);
        },
        ...mapActions("analytics", [
            "fetchClinicCurrentNumberPatients",
            "fetchClinicMixPatientsCount",
            "fetchClinicNumberOfAppointmentsVsComparableClinics",
            "fetchClinicInjectablesSpend",
            "fetchClinicNumberOfClientsNotBooked",
            "fetchClinicTreatmentMix",
            "fetchClinicAppointmentVolumes",
            "fetchClinicProductVolumes",
            "fetchClinicProductMix"
        ]),
        formatCurrency(value, round) {
            let val = (value / 1).toFixed(round)
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        getPercentClass(value) {
            const mainClass = 'subinfo-percent'
            if (value >= 0) {
                return mainClass + ' increased'
            }
            return mainClass + ' decreased'
        },
        getIconClass(value) {
            if (value >= 0) {
                return 'arrow_upward'
            }
            return 'arrow_downward'
        },
        exportProductPurchases(chartData) {
            let csv = "\ufeff";
            let filename = `product-purchases`
            csv += "Date, Product Purchases ($)\n";

            chartData.labels.forEach((date, index) => {
                csv += `${date},${chartData.datasets[0].data[index]}\n`;
            });

            utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
        },
        exportPatientCount(chartData) {
            let csv = "\ufeff";
            let filename = `patient-count-chart`
            csv += "Date, First Time Patients, Returning Patients\n";

            chartData.labels.forEach((date, index) => {
                csv += `${date},${chartData.datasets[0].data[index]},${chartData.datasets[1].data[index]}\n`;
            });

            utils.exportToCSV(csv, `${filename}${moment().format('YYMMDDHHmm')}`)
        },
        fetchClinicPatients() {
            // Reset currentAppointments
            this.currentAppointments = {
                total_appointments: 0
            }
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD")
            }
            this.loadingCharts.totalAppointments = true
            this.fetchClinicCurrentNumberPatients(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.currentAppointments = res.data[0]

                this.loadingCharts.totalAppointments = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.totalAppointments = false;
                });
        },
        fetchClinicCustomerMix() {
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD")
            }
            this.loadingCharts.customerMix = true
            this.fetchClinicMixPatientsCount(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.customerMix = res.data[0]

                this.loadingCharts.customerMix = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.customerMix = false;
                });
        },
        fetchClinicAppointmentPerformance() {
            console.log('home', this.selectedConfiguration);
            // Reset Chart
            this.appointmentPerformanceData = {
                "labels": [],
                "datasets": []
            }

            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD"),
                isHighPerformers: this.selectedConfiguration.isHighPerformers,
                selectedGeo: this.selectedConfiguration.selectedGeo,
                selectedInjector: this.selectedConfiguration.selectedInjector
            }
            this.loadingCharts.appointmentPerformance = true

            this.$emit('disableConfig', true);

            this.fetchClinicNumberOfAppointmentsVsComparableClinics(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.initializeAppointmentPerformanceData(res.data)

                this.loadingCharts.appointmentPerformance = false;

                this.$emit('disableConfig', false);
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.appointmentPerformance = false;

                    this.$emit('disableConfig', false);
                });
        },
        initializeAppointmentPerformanceData(data) {
            const self = this;
            // get all selected clinic data
            const selectedClinicData = filter(data, function (o) { return o.clinic_name === "Your Clinic" });
            const topClinicData = filter(data, function (o) { return o.clinic_name !== "Your Clinic" });
            const monthYear = selectedClinicData.map(({ month }) => `${this.moment(month.toString(), 'YYYY-MM').format('MMM YYYY')}`)
            const appointmentX = selectedClinicData.map(({ total_appointments }) => total_appointments)
            const appointmentY = topClinicData.map(({ total_appointments }) => total_appointments)

            const selectedClinicDataAppointments = appointmentX.map((value, index) => {
                return {
                    x: appointmentY[index],
                    y: value
                }
            })

            const topClinicDataAppointments = appointmentX.map((value, index) => {
                return {
                    x: value,
                    y: appointmentY[index]
                }
            })

            this.appointmentPerformanceData.labels = monthYear
            this.appointmentPerformanceData.datasets = [{
                "label": this.selectedNurseName ? this.selectedNurseName : "Your Clinic",
                "data": selectedClinicDataAppointments,
                "backgroundColor": "#236E55"
            }, {
                "label": this.selectedNurseName ? "Benchmark Nurses" : "Benchmark Clinics",
                "data": topClinicDataAppointments,
                "backgroundColor": "#99E5DB"
            }]
        },
        fetchClinicCurrentInjectablesSpend() {
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD")
            }
            this.loadingCharts.injectablesSpend = true
            this.fetchClinicInjectablesSpend(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.currentInjectablesSpend = res.data[0]

                this.loadingCharts.injectablesSpend = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.injectablesSpend = false;
                });

        },
        fetchClinicCurrentNumberOfClientsNotBooked() {
            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD")
            }
            this.loadingCharts.clientsNotBooked = true
            this.fetchClinicNumberOfClientsNotBooked(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.currentNumberOfClientsNotBooked = res.data[0]

                this.loadingCharts.clientsNotBooked = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.clientsNotBooked = false;
                });

        },
        fetchClinicTreatmentMixData() {
            // Reset Chart
            this.treatmentMixYourClinicData = {
                labels: [],
                datasets: []
            }

            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD")
            }
            this.loadingCharts.treatmentMix = true
            this.fetchClinicTreatmentMix(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length) {
                        // const defaultTreatmentVal = {
                        //     filler: 0,
                        //     toxin: 0,
                        //     filler_and_toxin: 0,
                        //     filler_percentage: 0,
                        //     toxin_percentage: 0,
                        //     filler_and_toxin_percentage: 0
                        // }

                        let selectedClinicTreatmentMix = res.data

                        const toxinOnlyData = find(selectedClinicTreatmentMix, function (o) { return o.category === 'Toxin Only' && o.percentage > 0; });
                        const fillerOnlyData = find(selectedClinicTreatmentMix, function (o) { return o.category === 'Filler Only' && o.percentage > 0; });
                        const bothData = find(selectedClinicTreatmentMix, function (o) { return o.category === 'Both' && o.percentage > 0; });
                        const otherData = find(selectedClinicTreatmentMix, function (o) { return o.category === 'Others' && o.percentage > 0; });

                        // const selectedClinicTreatmentMix = res.data[0] ? res.data[0] : defaultTreatmentVal
                        // const topAverageTreatmentMix = res.data[1] ? res.data[1] : defaultTreatmentVal

                        const treatmentMixLabel = this.selectedNurseName ? "Treatment Mix - " + this.selectedNurseName : "Treatment Mix - Your clinic"
                        this.treatmentMixYourClinicData.labels = [treatmentMixLabel]
                        if (toxinOnlyData || fillerOnlyData || bothData) {
                            this.treatmentMixYourClinicData.datasets = [
                                {
                                    type: 'bar',
                                    label: 'Anti-Wrinkle',
                                    data: [toxinOnlyData ? toxinOnlyData.percentage : 0],
                                    backgroundColor: '#236E55',
                                    labelColor: '#fff',
                                },
                                {
                                    type: 'bar',
                                    label: 'Filler',
                                    data: [fillerOnlyData ? fillerOnlyData.percentage : 0],
                                    backgroundColor: '#99E5DB',
                                    labelColor: '#000'
                                },
                                {
                                    type: 'bar',
                                    label: 'Anti-wrinkle + Filler',
                                    data: [bothData ? bothData.percentage : 0],
                                    backgroundColor: '#5EAA98',
                                    labelColor: '#fff'
                                },
                                {
                                    type: 'bar',
                                    label: 'Other Injectables',
                                    data: [otherData ? otherData.percentage : 0],
                                    backgroundColor: '#D6D6D6',
                                    labelColor: '#000'
                                }
                            ]
                        } else {
                            // this is to hide chart if there's no data
                            this.treatmentMixYourClinicData = {
                                labels: [],
                                datasets: []
                            }
                        }

                        // this.treatmentMixTopData = [
                        //     { sets: ['Filler Only'], size: topAverageTreatmentMix.filler_percentage },
                        //     { sets: ['Anti-wrinkle'], size: topAverageTreatmentMix.toxin_percentage },
                        //     { sets: ['Filler Only', 'Anti-wrinkle'], size: topAverageTreatmentMix.filler_and_toxin_percentage }
                        // ]
                    }

                this.loadingCharts.treatmentMix = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.treatmentMix = false;
                });

        },
        fetchClinicCurrentAppointmentVolumes() {
            // Reset Chart
            this.appointmentVolumesData = {
                labels: [],
                datasets: []
            }

            const payload = {
                clinic: this.selectedClinic
            }
            this.loadingCharts.appointmentVolumes = true
            this.fetchClinicAppointmentVolumes(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.initializeAppointmentVolumes(res.data)

                this.loadingCharts.appointmentVolumes = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.appointmentVolumes = false;
                });

        },
        initializeAppointmentVolumes(data) {
            const self = this;

            let latestData = data;

            // get latest 6 months of data
            if (latestData.length > 6) {
                latestData = slice(data, data.length - 6)
            }

            const dateLabels = latestData.map((value) => {
                return self.moment(value.trunc_month, 'YYYY-MM-DD').format('MMM YYYY')
            })

            const originalData = data.map(({ volume }) => volume)
            latestData = latestData.map(({ volume }) => volume)

            this.appointmentVolumesData.labels = dateLabels
            this.appointmentVolumesData.datasets = [{
                "label": "Appointment Volume",
                "backgroundColor": "#236E55",
                "borderColor": "#236E55",
                "data": latestData,
                "originalData": originalData
            }]
        },
        fetchClinicCurrentProductVolumes() {
            // Reset Chart
            this.productVolumesData = {
                "labels": [],
                "datasets": []
            }

            const payload = {
                clinic: this.selectedClinic
            }
            this.loadingCharts.productVolumes = true
            this.fetchClinicProductVolumes(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.initializeProductVolumes(res.data)

                this.loadingCharts.productVolumes = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.productVolumes = false;
                });

        },
        initializeProductVolumes(data) {
            const self = this;

            let latestToxinData = data;
            let latestFillerData = data;

            // get latest 6 months of toxin/filler data
            if (latestToxinData.length > 6 && latestFillerData.length > 6) {
                latestToxinData = slice(latestToxinData, latestToxinData.length - 6)
                latestFillerData = slice(latestFillerData, latestFillerData.length - 6)
            }

            const dateLabels = latestToxinData.map((value) => {
                return self.moment(value.trunc_month, 'YYYY-MM-DD').format('MMM YYYY')
            })

            const originalToxinData = data.map(({ toxin_volume }) => toxin_volume)
            const originalFillterData = data.map(({ filler_volume }) => filler_volume)
            const latestToxinVolumeValues = latestToxinData.map(({ toxin_volume }) => toxin_volume)
            const latestFillerVolumeValues = latestFillerData.map(({ filler_volume }) => filler_volume)

            const originalToxinProductVolume = originalToxinData.map((value, index) => {
                return {
                    x: originalFillterData[index],
                    y: value
                }
            })

            const originalFillerProductVolume = originalToxinData.map((value, index) => {
                return {
                    x: value,
                    y: originalFillterData[index]
                }
            })

            const toxinProductVolume = latestToxinVolumeValues.map((value, index) => {
                return {
                    x: latestFillerVolumeValues[index],
                    y: value
                }
            })

            const fillerProductVolume = latestToxinVolumeValues.map((value, index) => {
                return {
                    x: value,
                    y: latestFillerVolumeValues[index]
                }
            })

            this.productVolumesData.labels = dateLabels
            this.productVolumesData.datasets = [{
                "label": "Anti-wrinkle Volume (units)",
                "data": toxinProductVolume,
                "backgroundColor": "#236E55",
                "originalData": originalToxinProductVolume,
            }, {
                "label": "Filler Volume (mL)",
                "data": fillerProductVolume,
                "backgroundColor": "#99E5DB",
                "originalData": originalFillerProductVolume
            }]
        },
        fetchClinicCurrentProductMix() {
            // Reset chart
            this.productMixData = {
                labels: [],
                datasets: []
            }

            const payload = {
                clinic: this.selectedClinic,
                nurse: this.selectedNurse ? [this.selectedNurse] : [],
                start: this.moment(this.selectedDate[0]).format("YYYY-MM-DD"),
                end: this.moment(this.selectedDate[1]).format("YYYY-MM-DD")
            }
            this.loadingCharts.productMix = true
            this.fetchClinicProductMix(payload).then((res) => {
                if (res && res.data)
                    if (res.data.length)
                        this.initializeProductMix(res.data[0])

                this.loadingCharts.productMix = false;
            })
                .catch((err) => {
                    console.error("##ERROR: ", err.message);
                    this.loadingCharts.productMix = false;
                });

        },
        initializeProductMix(data) {
            if (data.toxin_percentage || data.filler_percentage || data.skincare_percentage || data.consumables_percentage || data.equipment_percentage || data.other_injectables_percentage) {
                const toxinPercent = (data.toxin_percentage) ? data.toxin_percentage : 0
                const fillerPercent = (data.filler_percentage) ? data.filler_percentage : 0
                const skincarePercent = (data.skincare_percentage) ? data.skincare_percentage : 0
                const consumablesPercent = (data.consumables_percentage) ? data.consumables_percentage : 0
                const equipmentPercent = (data.equipment_percentage) ? data.equipment_percentage : 0
                const otherInjectablesPercent = (data.other_injectables_percentage) ? data.other_injectables_percentage : 0

                const toxinAmount = (data.toxin_spend_amount) ? data.toxin_spend_amount : 0
                const fillerAmount = (data.filler_spend_amount) ? data.filler_spend_amount : 0
                const skincareAmount = (data.skincare_spend_amount) ? data.skincare_spend_amount : 0
                const consumablesAmount = (data.consumables_spend_amount) ? data.consumables_spend_amount : 0
                const equipmentAmount = (data.equipment_spend_amount) ? data.equipment_spend_amount : 0
                const otherInjectablesAmount = (data.other_injectables_spend_amount) ? data.other_injectables_spend_amount : 0

                this.productMixData = {
                    labels: [
                        'Anti-Wrinkle: ' + toxinPercent + '%',
                        'Filler: ' + fillerPercent + '%',
                        'Skincare: ' + skincarePercent + '%',
                        'Consumables: ' + consumablesPercent + '%',
                        'Equipment: ' + equipmentPercent + '%',
                        'Other Injectables: ' + otherInjectablesPercent + '%'
                    ],
                    datasets: [{
                        label: '',
                        data: [
                            toxinPercent,
                            fillerPercent,
                            skincarePercent,
                            consumablesPercent,
                            equipmentPercent,
                            otherInjectablesPercent
                        ],
                        hoverLabel: [
                            'Anti-Wrinkle: $' + this.formatCurrency(toxinAmount, 2) + ' (' + toxinPercent + '%)',
                            'Filler: $' + this.formatCurrency(fillerAmount, 2) + ' (' + fillerPercent + '%)',
                            'Skincare: $' + this.formatCurrency(skincareAmount, 2) + ' (' + skincarePercent + '%)',
                            'Consumables: $' + this.formatCurrency(consumablesAmount, 2) + ' (' + consumablesPercent + '%)',
                            'Equipment: $' + this.formatCurrency(equipmentAmount, 2) + ' (' + equipmentPercent + '%)',
                            'Other Injectables: $' + this.formatCurrency(otherInjectablesAmount, 2) + ' (' + otherInjectablesPercent + '%)'
                        ],
                        backgroundColor: [
                            '#236E55',
                            '#99E5DB',
                            '#FFC060',
                            '#D6BFEF',
                            '#6735EE',
                            '#D6D6D6'
                        ],
                        hoverOffset: 4,
                        borderWidth: 0
                    }]
                }

                // const productMixDataObj = {
                //     'Anti-Wrinkle': (data.toxin_percentage) ? data.toxin_percentage : 0,
                //     'Filler': (data.filler_percentage) ? data.filler_percentage : 0,
                //     'Skincare' : (data.skincare_percentage) ? data.skincare_percentage : 0,
                //     'Consumables' : (data.consumables_percentage) ? data.consumables_percentage : 0,
                //     'Equipment' : (data.equipment_percentage) ? data.equipment_percentage : 0,
                //     'Other Injectables': (data.other_injectables_percentage) ? data.other_injectables_percentage : 0
                // }
                // this.productMixData = omitBy(productMixDataObj, (value) => value === 0);
            } else {
                // this is to hide chart if there's no data
                this.productMixData = {
                    labels: [],
                    datasets: []
                }
            }
        },
        fetchClinicPerformance() {
            if (this.selectedClinic.length) {
                if (!this.loadingCharts.totalAppointments) {
                    this.fetchClinicPatients()
                }
                if (!this.loadingCharts.customerMix) {
                    this.fetchClinicCustomerMix()
                }
                if (!this.loadingCharts.appointmentPerformance) {
                    this.fetchClinicAppointmentPerformance()
                }
                if (!this.loadingCharts.injectablesSpend) {
                    this.fetchClinicCurrentInjectablesSpend()
                }
                if (!this.loadingCharts.clientsNotBooked) {
                    this.fetchClinicCurrentNumberOfClientsNotBooked()
                }
                if (!this.loadingCharts.treatmentMix) {
                    this.fetchClinicTreatmentMixData()
                }
                if (!this.loadingCharts.appointmentVolumes) {
                    this.fetchClinicCurrentAppointmentVolumes()
                }
                if (!this.loadingCharts.productVolumes) {
                    this.fetchClinicCurrentProductVolumes()
                }
                if (!this.loadingCharts.productMix) {
                    this.fetchClinicCurrentProductMix()
                }
            }
        },
    },
    watch: {
        loadingDropdownFilter: {
            handler(newVal) {
                this.loadingCharts.totalAppointments = newVal
                this.loadingCharts.customerMix = newVal
                this.loadingCharts.totalPurchaseAmount = newVal
                this.loadingCharts.appointmentPerformance = newVal
                this.loadingCharts.lineChart = newVal
                this.loadingCharts.injectablesSpend = newVal
                this.loadingCharts.clientsNotBooked = newVal
                this.loadingCharts.treatmentMix = newVal
                this.loadingCharts.appointmentVolumes = newVal
                this.loadingCharts.productVolumes = newVal
                this.loadingCharts.productMix = newVal
            },
            immediate: true,
        },
        forceFetch: {
            handler(newVal) {
                if (newVal) {
                    this.fetchClinicPerformance();

                    this.$emit('resetForceFetch');
                }
            }
        },
        selectedNurse: {
            handler(newVal) {
                if (!this.loadingDropdownFilter)
                    this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedClinic: {
            handler(newVal) {
                if (!this.loadingDropdownFilter)
                    this.fetchClinicPerformance()
            },
            immediate: true,
        },
        selectedDate: {
            handler(newVal) {
                if (newVal.length) {
                    if (!this.loadingDropdownFilter)
                        this.fetchClinicPerformance()
                }
            },
            immediate: true,
        }
    },
}
</script>
<style>
#clinic-dashboard-home .chart-widget {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.widget-divider-wrapper {
    padding-top: 10px;
    width: 100%;
}

.divider-right {
    border-right: 1px solid #F2F2F2;
}

.top-grids .widget-title {
    font-size: 14px;
}

.count-percentage {
    font-size: 24px;
}

.count-number {
    font-size: 11px;
    margin-bottom: 12px;
}

.count-label {
    font-size: 10px;
}

.mix-grids {
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 12px;
    margin-bottom: 12px;
}

.mix-grids .chart-widget {
    display: block !important;
}

.mix-grids .venn-div svg {
    margin: auto;
    display: block;
}

.legend-wrapper {
    gap: 12px;
}

.legend-wrapper .legend-label {
    font-size: 12px;
}

.overall-market {
    background-color: #236E55;
    color: #F2F2F2;
    font-family: 'InterSemiBold';
    font-size: 16px;
    padding: 4px;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
}

.overall-market-grids {
    /* grid-template-columns: 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 12px;
}

[data-venn-sets="Filler Only"] path {
    fill: #4A6FA5 !important;
    fill-opacity: 0.5 !important;
}

[data-venn-sets="Anti-wrinkle"] path {
    fill: #99E5DB !important;
    fill-opacity: 0.5 !important;
}

[data-venn-sets="Filler Only_Anti-wrinkle"] path {
    fill: #000 !important;
}

#productMixData {
    max-height: 260px;
    padding-bottom: 0;
    height: 100%;
}

#productMixData svg {
    height: 100%;
    margin: 0 auto;
    left: 0;
    right: 0;
}

@media only screen and (max-width: 991px) {
    .mix-grids .chart-widget {
        min-height: auto !important;
    }
}

@media only screen and (max-width: 1068px) {
    .mix-grids {
        display: block !important;
    }
}
</style>
